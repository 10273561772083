<template>
  <CCard>
    <CCardBody>
      <div class="d-flex align-items-center mb-2">
        <TMessage content="Tracking" bold noTranslate />
      </div>
      <CRow>
        <CCol col="8" sm="10">
          <TInputText
            placeholder="Input tracking"
            class="w-100"
            :value.sync="tracking_code"
            @update:value="tracking_code = $event"
          />
        </CCol>
        <CCol col="4" sm="2">
          <TButton
            content="Print"
            class="w-100"
            icon="cil-print"
            :options="{ disabled: trackingLoading || !tracking_code.trim() }"
            @click="printTracking"
          />
        </CCol>
      </CRow>
      <div class="d-flex align-items-center mb-2 mt-3">
        <TMessage content="Pallet" bold noTranslate />
      </div>
      <CRow>
        <CCol col="8" sm="10">
          <TInputText
            placeholder="Input pallet id"
            class="w-100"
            :value.sync="pallet_id"
            @update:value="pallet_id = $event"
          />
        </CCol>
        <CCol col="4" sm="2">
          <TButton
            content="Print"
            class="w-100"
            icon="cil-print"
            :options="{ disabled: palletLoading || !pallet_id.trim() }"
            @click="printPallet"
          />
        </CCol>
      </CRow>
      <div class="d-flex align-items-center mb-2 mt-3">
        <TMessage content="SFA" bold noTranslate />
      </div>
      <CRow>
        <CCol col="8" sm="10">
          <TInputText
            placeholder="Input sfa id"
            class="w-100"
            :value.sync="sfa_id"
            @update:value="sfa_id = $event"
          />
        </CCol>
        <CCol col="4" sm="2">
          <TButton
            content="Print"
            class="w-100"
            icon="cil-print"
            :options="{ disabled: sfaLoading || !sfa_id.trim() }"
            @click="printSFA"
          />
        </CCol>
      </CRow>
      <div class="d-flex align-items-center mb-2 mt-3">
        <TMessage content="SKU" noTranslate bold />
      </div>
      <CRow class="align-items-end">
        <CCol col="12" sm="5" class="mb-3">
          <TInputText
            label="SKU Id"
            placeholder="SKU Id"
            class="w-100"
            :value.sync="sku_id"
            @update:value="sku_id = $event"
          />
        </CCol>
        <CCol col="12" sm="5" class="mb-3">
          <CRow>
            <CCol col="6" sm="6">
              <TMessage content="Start index" noTranslate bold class="mb-2" />
              <TInputNumber
                :value.sync="start_index"
                @update:value="start_index = $event"
              />
            </CCol>
            <CCol col="6" sm="6">
              <TMessage content="End index" noTranslate bold class="mb-2" />
              <TInputNumber
                :value.sync="end_index"
                @update:value="end_index = $event"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol col="12" sm="2" class="mb-3">
          <TButton
            content="Print"
            class="w-100"
            icon="cil-print"
            :options="{ disabled: skuLoading || !sku_id.trim() }"
            @click="printSKU"
          />
        </CCol>
      </CRow>
      <div class="d-flex align-items-center mb-2 mt-3">
        <TMessage content="Create and print bulk tracking" bold />
      </div>
      <CRow class="align-items-end">
        <CCol col="12" sm="5" class="mb-3">
          <TInputText
            label="Quantity"
            placeholder="Quantity"
            class="w-100"
            :value.sync="bulk_tracking.quantity"
            @update:value="bulk_tracking.quantity = $event"
          />
        </CCol>
        <CCol col="12" sm="5" class="mb-3">
          <SSelectAgency
            label="Agency"
            :prepend="[]"
            :value.sync="bulk_tracking.agency"
            @update:value="bulk_tracking.agency = $event"
          />
        </CCol>
        <CCol col="12" sm="2" class="mb-3">
          <TButton
            content="Print"
            class="w-100"
            icon="cil-print"
            :options="{ disabled: sfaLoading || true }"
            @click="printBulkTracking"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import label from "../../../mixins/label";
import { mapGetters } from "vuex";
export default {
  mixins: [label],
  data() {
    return {
      loading: false,
      tracking_code: "",
      pallet_id: "",
      sfa_id: "",
      sku_id: "",
      bulk_tracking: {},
      start_index: 1,
      end_index: 1,
    };
  },
  computed: {
    ...mapGetters({
      trackingCreating: "order.trackings.creating",
      trackingLoading: "order.trackings.loading",
      palletLoading: "warehouse.pallets.detail.loading",
      sfaLoading: "warehouse.sfas.detail.loading",
      skuLoading: "warehouse.boxes.detail.loading",
    }),
  },
  methods: {
    printTracking() {
      this.$store
        .dispatch("order.trackings.apply-query", {
          "filter[code]": this.tracking_code,
        })
        .then(({ data }) => {
          if (data.length) {
            const message = {
              ...this.message,
              type: "TRACKING",
              tracking: data[0].code,
              quantity: 2,
              pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
            };
            this.$store.dispatch(
              "printer.send-message",
              JSON.stringify(message)
            );
          } else {
            this.$store.commit("toasts.push", {
              message: "Not found",
              type: "danger",
            });
          }
        })
        .finally(() => {
          this.tracking_code = "";
        });
    },
    printBulkTracking() {
      for (let i = 0; i < this.bulk_tracking.quantity; i++) {
        this.$store
          .dispatch("order.trackings.create", {})
          .then((data) => {
            const message = {
              ...this.message,
              type: "TRACKING",
              tracking: data.id,
              sfaQuantity: 0,
              pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
            };
            this.$store.dispatch(
              "printer.send-message",
              JSON.stringify(message)
            );
          })
          .finally(() => {
            this.tracking_code = "";
          });
      }
    },
    printSKU() {
      this.$store
        .dispatch("warehouse.boxes.detail.fetch", this.sku_id)
        .then((data) => {
          const message = {
            ...this.message,
            type: "SKU",
            sfaId: data?.sfa_id || "",
            sku: data.id,
            quantity: data.duplicate,
            itemQuantity: data?.items?.length || 0,
            startIndex: this.start_index,
            endIndex: this.end_index,
            pageSize: "1", Barcode: "1", map: "1", place: "2", region: "Chiba",
          };
          this.$store.dispatch("printer.send-message", JSON.stringify(message));
        })
        .finally(() => {
          this.sku_id = "";
          this.start_index = 1;
          this.end_index = 1;
        });
    },
    printPallet() {
      this.$store
        .dispatch("warehouse.pallets.detail.fetch", this.pallet_id)
        .then((data) => {
          this.pallet_id = "";
          const message = {
            ...this.labelMessage,
            type: "PALLET",
            palletId: data.id,
            quantity: 2,
            pageSize: "1",
          };
          this.$store.dispatch("printer.send-message", JSON.stringify(message));
        })
        .finally(() => {
          this.pallet_id = "";
        });
    },
    printSFA() {
      this.$store
        .dispatch("warehouse.sfas.detail.fetch", this.sfa_id)
        .then((data) => {
          this.sfa_id = "";
          const message = {
            ...this.message,
            type: "SFA",
            sfaId: data.id,
            tracking: data.tracking_code,
            sfaQuantity: data.quantity,
            pageSize: "1",
          };
          this.$store.dispatch("printer.send-message", JSON.stringify(message));
        })
        .finally(() => {
          this.sfa_id = "";
        });
    },
  },
};
</script>
